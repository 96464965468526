@import '../variables.css';
.productContainer {
    padding: 20px;
    text-align: center;
    width: 60%;
    margin: 0 auto;
    color: var(--text-dark-color);
  }
  
  .productDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .productImage{
    width: 40%;
  }
  .productImage img {
    width: 80%; /* Adjust the size as needed */
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto
  }
  
  .productInfo {
    margin-top: 20px;
    padding: 20px;
    width: 60%;
    text-align: center; /* Center the text */
  }
  
  .productInfo h2 {
    margin-top: 0;
    font-size: 2em;
    font-weight: 400;
  }
  
  .price {
    font-size: 2.4em;
    font-weight: 600;
  }
  
  .description {
    margin-top: 10px;
    font-size: 1em;
    line-height: 1.5;
    width: 80%;
    margin: 0 auto;
  }
  
  .orderForm {
    margin-top: 20px;
  }
  

  .orderForm input {
    width: 80%; /* Set the input width to 90% of its container */
    padding: 12px; /* Adjust padding as needed */
    margin-bottom: 10px; /* Spacing between inputs */
    box-sizing: border-box; /* Ensures padding and border are included in the width */
    border: 1px solid #ccc; /* Border style */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Font size */
    line-height: 1.5; /* Line height for readability */
    color: var(--text-dark-color);
  }
  
  .orderForm input:focus {
    outline: none; /* Remove default focus outline */
    border-color: #007bff; /* Customize focus border color */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Optional: Add box shadow on focus */
  }
  
  .orderForm input::placeholder {
    color: var(--placeholder-color); /* Placeholder text color */
    text-align: center;
  }
  
  
  .orderForm button {
    padding: 10px 20px;
    background-color: var(--button-primary-background-color);
    color: var(--button-primary-text-color);
    font-weight: var(--button-font-weight);
    font-size: 1.2em;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 60%;
  }
  
  .orderForm button:hover {
    background-color: var(--button-primary-hover-background-color);
    color: var(--button-primary-hover-text-color);
  }
  
  @media (min-width: 768px) {
    .productDetails {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .productImage, .productInfo {
      flex: 1;
      margin: 0 20px;
    }
  
    .productInfo {
      text-align: center; /* Ensure text is centered on larger screens as well */
    }

  }
   
  @media (max-width: 768px) {
    
    .productImage{
      width: 100%;
    }
    .productContainer {
      width: 80%;
    }
    .productImage img {
      width: 100%; /* Adjust the size as needed */
      height: auto;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin: auto
    }
    .description {
      width: 100%;
      padding: 0;
    }
    .productInfo {
      width: 100%;
      padding: 0;
    }
    .price{
      padding: 0;
    }
    
  }