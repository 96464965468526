/* Import variables if needed */
@import '../variables.css';

/* General hero section styles */
.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 87vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: var(--text-light-color); /* White text color for better contrast */
  text-align: center;
}

/* Overlay styles */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(4, 12, 43, 0.812); /* Transparent black layer */
  z-index: 1; /* Below the content */
}

/* Content container styles */
.content {
  position: relative;
  z-index: 2; /* Above the overlay */
  max-width: 80%;
  margin: 0 auto;
}

/* Slogan styles */
.slogan {
  font-size: 3rem;
  margin-bottom: 1rem;
}

/* Description styles */
.description {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

/* Order button styles */
.orderButton {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: var(--button-primary-text-color);
  background-color: var(--button-primary-background-color);
  font-weight: var(--button-font-weight);
  border: none;
  border-radius: var(--button-border-radius);
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Button hover styles */
.orderButton:hover {
  background-color: var(--button-primary-hover-background-color);
  color: var(--button-primary-hover-text-color);
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .slogan {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
  }

  .orderButton {
    font-size: 1rem;
    padding: 8px 16px;
  }

  .advantagesList {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  .advantageIcon {
    width: 50px; /* Adjust size of images */
    height: 50px; /* Adjust size of images */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 10px; /* Adjust spacing between icon and text */
  }
 
  .advantageItem {
    margin-bottom: 20px; /* Adjust spacing between items */
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .advantageItem span{
    margin-left: 10px;
  }
}

/* Styles for advantages in a row on larger screens */
@media (min-width: 769px) {
  .advantagesList {
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 1.2em;
    width: 100%;
  }

  .advantageItem {
    margin-bottom: 20px; /* Adjust spacing between items */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .advantageIcon {
    width: 70px; /* Adjust size of images */
    height: 70px; /* Adjust size of images */
    margin-right: 10px; /* Adjust spacing between icon and text */
  }

}
