/* Return.module.css */

.returnInfo {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: var(--text-dark-color);
    text-align: center;
  }
  
  .returnInfo h1 {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-decoration: underline;
  }
  
  .returnInfo h2 {
    font-size: 1.2em;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .returnInfo p {
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .returnInfo a {
    text-decoration: none;
    color: var(--button-primary-background-color);
    font-weight: var(--button-font-weight);
  }
  
  .returnInfo a:hover {
    text-decoration: underline;
  }
  