@import '../variables.css';

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 13vh;
  background-color: var(--header-color); /* Adjust background color as needed */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
  position: fixed; /* Make the header fixed */
  top: 0;
  width: 100%; /* Ensure the header spans the full width */
  z-index: 1000; /* Ensure the header is on top of other elements */
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 13vh; 
  width: auto;
}

.logo {
  max-height: 13vh; /* Ensure the image height is constrained */
  width: auto;
  cursor: pointer;
  object-fit: contain;
}

/* Add padding to the top of the main content to avoid it being hidden behind the fixed header */
.main-content {
  padding-top: 13vh; /* Match the height of the header */
}
