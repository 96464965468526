@import '../variables.css';

.footer {
  background-color: var(--footer-background-color);
  padding: 20px 0;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.linksRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 60%;
  flex-wrap: wrap;
}

.footerLink {
  text-decoration: none;
  color: var(--footer-link-color);
  padding: 5px 10px;
  white-space: nowrap;
  font-weight: 550;
}

.footerLink:hover {
  text-decoration: none;
}

.socialIconsRow {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  overflow: auto;
}

.socialIcon {
  text-decoration: none;
  margin: 0 10px;
}

.socialIcon img {
  width: 30px;
  height: auto;
}

.socialIcon:hover {
  opacity: 0.8;
}

.footerCopyright {
  color: var(--footer-copy-color);
}

/* New section for sale information */
.saleSection {
  position: relative;
  width: 100%;
  height: 300px; /* Adjust height as needed */
  background-image: url('../assets/sale-background.webp'); /* Replace with your sale background image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Text color for sale information */
  font-size: 1.5rem;
  text-align: center;
  font-weight: 800;
}

.saleOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay with 60% opacity */
  backdrop-filter: blur(8px);
  
}

@media (max-width: 900px) {
  .linksRow {
    flex-direction: column;
  }
}
