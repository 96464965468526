@import '../variables.css';

.cookieBar {
  background-color: var(--cookies-background-color);
  padding: 10px 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box; /* Ensures padding is included in the total width */
  flex-direction: column; /* Stack items vertically */
  text-align: center; /* Center text */
}

.cookieBar p {
  margin: 0 0 10px 0; /* Add margin below paragraph */
  font-size: 14px; /* Slightly smaller font size for mobile */
  color: var(--cookies-text-color);
}

.cookieBar a {
  color: var(--cookies-link-color);
  text-decoration: none;
  font-weight: var(--cookies-link-font-weight);
}

.cookieBar a:hover {
  text-decoration: underline;
}

.confirmButton {
  background-color: var(--button-secondary-background-color);
  color: var(--button-secondary-text-color);
  font-weight: var(--button-font-weight);
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  width: 100px; /* Fixed width for minimalistic design */
}

.confirmButton:hover {
  background-color: var(--button-secondary-hover-background-color);
  color: var(--button-secondary-hover-text-color);
}

/* Media Query for mobile devices */
@media (max-width: 768px) {
  .cookieBar {
    padding: 15px 5%; /* Add more padding for mobile */
  }

  .confirmButton {
    width: 100%; /* Make button take full width */
    text-align: center; /* Center text in the button */
  }
}
